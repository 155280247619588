import React, { ReactNode } from 'react'

import styled from 'styled-components'

import { configuration } from 'mmfintech-checkout-commons'
import { getPaymentMethodImageUrl } from 'mmfintech-commons'

interface MethodImageProps {
  method: string
  className?: string
  children?: ReactNode
}

export const MethodImage: React.FC<MethodImageProps> = ({ method, className, children }) => {
  //const url = `${configuration.readBackendConfig()}/api${getPaymentMethodImageUrl(method)}`
  // const webResource = useWebResource(url)

  return (
    <ImageWrapper className={className}>
      {<img src={`${configuration.readBackendConfig()}${getPaymentMethodImageUrl(method)}`} alt='' />}
      {children}
    </ImageWrapper>
  )
}

const ImageWrapper = styled.span`
  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0 0 0.5rem;

  width: 10rem;
  height: 10rem;
  border: 1px solid #d9d9d9;
  border-radius: 5px;

  .selected > & {
    //background-color: rgba(68, 204, 0, 0.1);
    border: 2px solid rgba(68, 204, 0, 0.5);
  }

  & > img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 5px;

    &.local {
      width: 80%;
      border-radius: 0;
    }
  }
`
