import { tr } from 'mmfintech-commons'

import { MethodImage } from '../MethodButton/MethodImage'
import { MethodPaneWrapper } from './MethodPane.styled'

interface MethodPaneProps {
  method: string
}

export const MethodPane = ({ method }: MethodPaneProps) =>
  method ? (
    <MethodPaneWrapper>
      <span>{tr('METADATA.PAYMENT_OPTIONS.' + method, method)}</span>
      <MethodImage method={method} />
    </MethodPaneWrapper>
  ) : null
